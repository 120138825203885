import React, { useEffect } from 'react';
import './Products.css';
import Header from './Header.js';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import WorkIcon from '@material-ui/icons/Work';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import Typography from '@material-ui/core/Typography';
import TransferWithinAStationOutlinedIcon from '@material-ui/icons/TransferWithinAStationOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import Sliderofproducts from './Sliderofproducts.js';
import Footertop from './Footertop.js';
import Footer from './Footer.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withTheme } from '@material-ui/styles';
import 'aos/dist/aos.css'; 
import Aos from 'aos';


const useStyles = makeStyles( (theme) => ({
  
    goDown: {
        color: '#ff6600',
        width: '100%',
        fontSize: '4rem',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          color: '#ffffff',
          fontSize: '5rem',
          
        },
      
        
    },

  
  }));








const Products = () => {

    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );
    const classes = useStyles();
    return (
        <div >
            <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/products.jpg'  })`, width:'100%' }} className="products" >
                <Header/>
                <div className="products_main">
                    <h1 className="solutions" > Products <br/> and <br/> Services </h1>
                    <ExpandMoreIcon className= "icon_scroll" />
                </div>
            </div>

            <Sliderofproducts/>
            <div className="products_main">
                <h1 data-aos="fade-right" className="sectors_tag" style = {{border: "none"}} > How we work ?</h1>
            </div>
            <div className="icons_mega">
                <div data-aos="zoom-in" className="icons" >
                    <div className="icon">
                    <WorkOutlineIcon className="icon_a" className="icon_a" style={{ color: '#ff6600' }}/>
                        <h2>Joint Venture</h2>
                        <Typography className="icon_text " variant="body2" color="textSecondary" component="p" >We are open to joint venture type of collaboration. Our patented and patent-pending solutions along with our technological know-how create competitive companies. </Typography>
                    </div>
                    <div className="icon">
                    <BusinessOutlinedIcon className="icon_a" className="icon_a" style={{ color: '#ff6600' }}/>
                        <h2>RaaS</h2>
                        <Typography variant="body2" color="textSecondary" component="p" > We are the only company providing Research as a Service solution. We work as a dedicated research center with our highly skilled experts on alloy and process development. </Typography>
                    </div>
                    <div className="icon">
                    <TransferWithinAStationOutlinedIcon className="icon_a" style={{ color: '#ff6600' }}/>
                        <h2>Tech Transfer</h2>
                        <Typography variant="body2" color="textSecondary" component="p" >Our solutions are market-ready and don't require new investment. Companies interested in our solutions can easily adapt our technologies to manufacturing. </Typography>
                    </div>
                    <div className="icon">
                    <GroupWorkOutlinedIcon className="icon_a" style={{ color: '#ff6600' }}/>
                        <h2>Project partnership</h2>
                        <Typography variant="body2" color="textSecondary" component="p" >Our experts can add value to any research project that you are working on. We are fully open to partnering in an existed or a future project. </Typography>
                    </div>
                </div>
                <div data-aos="zoom-in" className="icons" >
                    <div className="icon">
                    <LabelOutlinedIcon className="icon_a" style={{ color: '#ff6600', fontSize: 40  }}/>
                        <h2>White Labelling</h2>
                        <Typography variant="body2" color="textSecondary" component="p" > Our solutions are market-ready and don't require new investment. Companies interested in our solutions can easily adapt our technologies and start manufacturing under our name.</Typography>
                    </div>
                    <div className="icon">
                    <PhoneOutlinedIcon className="icon_a" style={{ color: '#ff6600', fontSize: 40  }}/>
                        <h2>Consultancy</h2>
                        <Typography variant="body2" color="textSecondary" component="p" > Do you have a problem to solve related to our know-how? Our skilled and experienced experts can help you to achieve results for any problem you encounter. </Typography>
                    </div>
                    <div className="icon">
                    <SchoolOutlinedIcon className="icon_a" style={{ color: '#ff6600', fontSize: 40  }}/>
                        <h2>Education</h2>
                        <Typography variant="body2" color="textSecondary" component="p" >Do you want your employees to be stay tuned about new technologies and tools about alloy development, thermodynamic modeling, failure analysis, and more? </Typography>
                    </div>
                    <div className="icon">
                    <WbIncandescentOutlinedIcon className="icon_a" style={{ color: '#ff6600', fontSize: 40  }}/>
                        <h2>Tests</h2>
                        <Typography variant="body2" color="textSecondary" component="p" >We can help you with Microstructural characterization, tensile test, Charpy test, ThermoCalc, prototype production, corrosion tests, failure analysis.  </Typography>
                    </div>
            </div>
            </div>
            <Footertop link="/contact" arkaplanrengi="#ff6600" righttext="Contact us" lefttoptext="Are you interested in our products or services ?"  leftbottomtext=" Feel free to contact us !!"  />
            <Footer/>

        </div>

     
    )
}

export default Products

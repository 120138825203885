import React, { useEffect } from 'react';
import Description from './Description';
import Footer from './Footer';
import Footertop from './Footertop';
import Header from './Header';
import Texttitle from './Texttitle.js';
import 'aos/dist/aos.css'; 
import Aos from 'aos';
import Tableof3gen from './Tableof3gen';
import Mechanicalsliderahss from './Mechanicalsliderahss';
import { useMediaQuery } from 'react-responsive';

const Ahss3gen = () => {

    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 780px)'
      });

    return (
        <div>
            <Header/>
            <div>
                <Texttitle 
                title='ADVANCED HIGH STRENGTH STEELS'
                caption = 'AHSS grades are mainly used by the automotive industry. They enable car manufacturers to reduce weights without sacrificing safety requirements. Our high strength and high formability grades open new ways for car manufacturers to reduce weight to further levels.'
             
                />
            </div>
            <Description 
            text= 'The trade-off between weight and safety has come to an end. Our 3rd gen products are above the curve. The highest possible strength/formability combinations are achieved by our cutting-edge nano austenite second phase technology. The best news is this technology does not require expensive alloying and offers higher weldability than TWIP steels with its unique low alloying element content. Low Si and Cr alloying make them preferred for defect-free coated steel production. ' 
            img= '/assets/autopart.jpg'
            />
                         {isDesktopOrLaptop ?
                <div>
                    <div data-aos="zoom-in" className="ahss_properties">
                        <Tableof3gen/>
                    </div>
                </div>
            :           
                <div data-aos="zoom-in" className="ahss_properties">
                    <Mechanicalsliderahss
                        img1= '/assets/H1000-Mech.png' 
                        img2= '/assets/HS1700-Mech.png'
                        img3= '/assets/HSE1300-Mech.png'
                        img4= '/assets/MS1400-Mech.png'
                                />   
                    </div>
            } 
            <Footertop link="/contact" arkaplanrengi="#03494e" righttext="Contact us" lefttoptext="Are you interested in our products or services ?"  leftbottomtext=" Feel free to contact us !!"  />
            <Footer/>

        </div>
    )
}

export default Ahss3gen

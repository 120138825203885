
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"

import "./Mechanicalsliderahss.css";


// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);


export default function App(props) {
  
  
  
  return (
    <>
    <Swiper pagination={true} className="mySwiper">
            <SwiperSlide  >
               <div className="slide" style={{backgroundImage: `url(${process.env.PUBLIC_URL + props.img1  })` }}>
               </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide" style={{backgroundImage: `url(${process.env.PUBLIC_URL + props.img2   })`}}>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide" style={{backgroundImage: `url(${process.env.PUBLIC_URL + props.img3  })`}}>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide" style={{backgroundImage: `url(${process.env.PUBLIC_URL + props.img4  })`}}>
                </div>
            </SwiperSlide>
  </Swiper>
    </>
  )
}
import React from 'react';
import './Navbar.css';

function Navbar() {
    return (
        <navbar className="navbar_main">
            <div className="navbar__mainleft"> 
                <h4 className="navbar_lef_element" > OBER</h4>
                <h4 className="navbar_lef_element2" >TECHNOLOGIES</h4>
            </div>
            <div className="navbar__mainright"> 
                <button className="navbar__button_main" > PRODUCTS </button>
                <button className="navbar__button" > SERVICES </button>
                <button className="navbar__button" > CONTACT </button>
            </div>
         
        </navbar>
    )
}

export default Navbar

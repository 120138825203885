import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import WorkIcon from '@material-ui/icons/Work';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import Typography from '@material-ui/core/Typography';
import TransferWithinAStationOutlinedIcon from '@material-ui/icons/TransferWithinAStationOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import { makeStyles, withTheme } from '@material-ui/styles';
import 'aos/dist/aos.css'; 
import Aos from 'aos';
import Footertop from './Footertop.js';


const About = () => {
    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );
    
    return (


        <div>
            <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/oberabout.jpg'  })`, width:'100%'}} className="products" >
                <Header/>
                <div  className="products_main">
                    <h1 style={{color: 'white', borderColor: 'white', textAlign:'center'}} className="solutions" > ABOUT <br/> US <br/> </h1>
                </div>
            </div>
            <div className="icons_mega">
                <div data-aos="zoom-in" className="icons" >
                    <div className="icon">
                    <WorkOutlineIcon className="icon_a" className="icon_a" style={{ color: '#ff6600' }}/>
                        <h2>Joint Venture</h2>
                        <Typography className="icon_text " variant="body2" color="textSecondary" component="p" >We are open to joint venture type of collaboration. Our patented and patent-pending solutions along with our technological know-how create competitive companies. </Typography>
                    </div>
                    <div className="icon">
                    <BusinessOutlinedIcon className="icon_a" className="icon_a" style={{ color: '#ff6600' }}/>
                        <h2>RaaS</h2>
                        <Typography variant="body2" color="textSecondary" component="p" > We are the only company providing Research as a Service solution. We work as a dedicated research center with our highly skilled experts on alloy and process development. </Typography>
                    </div>
                    <div className="icon">
                    <TransferWithinAStationOutlinedIcon className="icon_a" style={{ color: '#ff6600' }}/>
                        <h2>Tech Transfer</h2>
                        <Typography variant="body2" color="textSecondary" component="p" >Our solutions are market-ready and don't require new investment. Companies interested in our solutions can easily adapt our technologies to manufacturing. </Typography>
                    </div>
                    <div className="icon">
                    <GroupWorkOutlinedIcon className="icon_a" style={{ color: '#ff6600' }}/>
                        <h2>Project partnership</h2>
                        <Typography variant="body2" color="textSecondary" component="p" >Our experts can add value to any research project that you are working on. We are fully open to partnering in an existed or a future project. </Typography>
                    </div>
                </div>
                <div data-aos="zoom-in" className="icons" >
                    <div className="icon">
                    <LabelOutlinedIcon className="icon_a" style={{ color: '#ff6600', fontSize: 40  }}/>
                        <h2>White Labelling</h2>
                        <Typography variant="body2" color="textSecondary" component="p" > Our solutions are market-ready and don't require new investment. Companies interested in our solutions can easily adapt our technologies and start manufacturing under our name.</Typography>
                    </div>
                    <div className="icon">
                    <PhoneOutlinedIcon className="icon_a" style={{ color: '#ff6600', fontSize: 40  }}/>
                        <h2>Consultancy</h2>
                        <Typography variant="body2" color="textSecondary" component="p" > Do you have a problem to solve related to our know-how? Our skilled and experienced experts can help you to achieve results for any problem you encounter. </Typography>
                    </div>
                    <div className="icon">
                    <SchoolOutlinedIcon className="icon_a" style={{ color: '#ff6600', fontSize: 40  }}/>
                        <h2>Education</h2>
                        <Typography variant="body2" color="textSecondary" component="p" >Do you want your employees to be stay tuned about new technologies and tools about alloy development, thermodynamic modeling, failure analysis, and more? </Typography>
                    </div>
                    <div className="icon">
                    <WbIncandescentOutlinedIcon className="icon_a" style={{ color: '#ff6600', fontSize: 40  }}/>
                        <h2>Tests</h2>
                        <Typography variant="body2" color="textSecondary" component="p" >We can help you with Microstructural characterization, Tensile test, Charpy test, ThermoCalc, Prototype production, Corrosion tests, Failure analysis.  </Typography>
                    </div>
            </div>
            </div>
        <Footertop link="/solutions" arkaplanrengi="#ff6600" righttext="Solutions" lefttoptext="Do you want to know how do we help to reduce gas emmissions ?"  leftbottomtext=" Explore our products !!"  />
        <Footertop link="/contact" arkaplanrengi="#03494e" righttext="Contact us" lefttoptext="Are you interested in our products or services ?"  leftbottomtext=" Feel free to contact us !!"  />
        <Footer/>    
        </div>
    )
}

export default About

import React from 'react';
import Description from './Description';
import Footer from './Footer';
import Footertop from './Footertop';
import Header from './Header';
import Texttitle from './Texttitle.js';


function Bearing() {
    return (
        <div>
            <Header/>
            <Texttitle 
            title='BEARING STEELS'
            caption = 'Bearing steels are used for various sectors from aerospace to automotive. The life cycle is everything for bearing material. Our material offers a wear resistance almost double of the conventionally produced bearing parts, without employing any investment.'
            />
            <Description
                        text= 'It is vital to the extent of the life of a bearing component to avoid costly and time-consuming replacements. It is known that the mechanical properties of bearing steel are closely related to the corresponding microstructure parameters, especially the size and distribution of undissolved cementite particles and prior austenite grain size. Our carbide refinement heat treatment method raises the hardness and wears resistance of bearing steel without employing expensive alloying elements' 
                        img= '/assets/bearing.jpg'
                        />
            <Description
                        text= 'Our heat treatment technology makes bearing steels harder than conventionally heat-treated ones. B350 and Mar steels are not different alloys, what makes them special is the heat treatment that we invented.' 
                        img= '/assets/bearinghardness.jpg'
                        />
            <Description
                        text= 'Bearing steels with ultrafine carbides reduces weight loss under sliding forces and raises the wear resistance significantly.' 
                        img= '/assets/weightlosebearing.jpg'
                        />
            <Footertop link="/contact" arkaplanrengi="#03494e" righttext="Contact us" lefttoptext="Are you interested in our products or services ?"  leftbottomtext=" Feel free to contact us !!"/>
            <Footer/>
        </div>
    )
}

export default Bearing

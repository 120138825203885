import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import './Texttitle.css';
import 'aos/dist/aos.css'; 
import Aos from 'aos';



const Texttitle = (props) => {
    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );
    return (
        <div className="title_space">
            <div data-aos="zoom-in" className="title_main">
              <h1 > {props.title} </h1>     
            </div>
            <div data-aos="flip-left" className="title_caption">
                <Typography variant="body2" color="textSecondary" component="p"  > {props.caption} </Typography>   
            </div>
 
        </div>
    )
}

export default Texttitle;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppBar, CssBaseline } from '@material-ui/core';
import Header from './Header.js'
import Button from '@material-ui/core/Button';
import Sectionfirst from './Sectionfirst.js';
import Sectors from './Sectors.js';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import News from './News.js';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Products from './Products.js'
import Ahss from './Ahss.js';
import Footertop from './Footertop.js';
import Footer from './Footer.js';
import Bearing from './Bearing.js';
import About from './About.js';
import ScrollToTop from './ScrollToTop.js';
import Ahss3gen from './Ahss3gen.js';
import Hotstamping from './Hotstamping.js';
import Nickelfreestainless from './Nickelfreestainless.js';
import Armor from './Armor.js';
import Contact from './Contact.js';
import News2 from './News2.js';
import Ahssdeneme from './Ahssdeneme.js';

const useStyles = makeStyles( (theme) => ({

  root: {
    minHeight : '100vh',
    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/main_photo.jpg"  })`,
    width:'100%',
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    '@media  (max-width: 780px)' : { 
      minHeight : '50vh',

    }

  },

    



}));

function App() {
  const classes = useStyles();
  return (
    <Router>
    <div>
    <ScrollToTop>
        <Switch>
          <Route path="/solutions">
            <Products/>

          </Route>
          <Route path="/about">
            <About/>
          </Route>
          <Route path="/contact">
            <Contact/>
          </Route>
          <Route path="/products/ahss2gen">
            <Ahss/>
          </Route>
          <Route path="/products/ahss3gen">
            <Ahss3gen/>
          </Route>
          <Route path="/bearing">
            <Bearing/>
          </Route>
          <Route path="/novelhotstamping">
            <Hotstamping/>
          </Route>
          <Route path="/nickelfreestainless">
            <Nickelfreestainless/>
          </Route>
          <Route path="/armor">
            <Armor/>
          </Route>
          <Route path="/">
            <div className={classes.root}>
              <Header></Header>
              <Sectionfirst/>
            </div>
            <Sectors/>
            <News2 yaziarkaplanrengi="#0078A3" text1="TIME TO TRANSFORM" text2="R&D OUTSOURCING" text3="R&D outsourcing can fuel savings and growth." img= '/assets/handshake.jpg'
            righttexttitle="Explore our Research as a Service (RaaS) business model" 
            righttext1="Ober handles all the pains that can be faced in a research project. From finding appropriate grants to carrying out all necessary tests can be handled by Ober. You just get enjoyed with the results of the project and you can transform the know-how gained by the project with our periodically meetings and educations." 
            righttext2="You can keep the IP rights of the project, while Ober handles the strategy to carry out the project. Our experienced employees will reduce the pain while you get the results of the project."
            sagarkaplanrengi="#ff6600"
            />
            <div className={classes.news} >
              <News/>
            </div>
            <News2 yaziarkaplanrengi="#59507A" text1="Why Do We Need Steel" text2=" for A Better Future?" text3="Automobile industry had already started to employ lightweighting solutions for energy saving" img= '/assets/automobile.jpg'
                  righttexttitle="Explore our steel products for weight reduction" 
                  righttext1="On average, 900 kg of steel is used per vehicle. Once the steel is produced, its lifecycle is potentially endless. Steel can be recycled an infinite amount of times and be used without downgrading in quality. Steel scrap can be easily separated by its ferromagnetism. " 
                  righttext2=" Ober products change the way by their competitive price, strength, and formability. Our automotive steels are designed to compete with lightweight materials. It is more sustainable, it has lower cost and it offers higher adaptability to production."
                  sagarkaplanrengi="#0078A3"
            />
            <Footertop link="/solutions" arkaplanrengi="#ff6600" righttext="Solutions" lefttoptext="Do you want to know how do we help to reduce gas emmissions ?"  leftbottomtext=" Explore our products !!"  />
            <Footertop link="/contact" arkaplanrengi="#03494e" righttext="Contact us" lefttoptext="Are you interested in our products or services ?"  leftbottomtext=" Feel free to contact us !!"  />
            <Footer/>
            </Route>
          </Switch>
      </ScrollToTop>

    </div>

    </Router>

  );
}
export default App;


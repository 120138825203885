import React from 'react';
import Description from './Description';
import Footer from './Footer';
import Footertop from './Footertop';
import Header from './Header';
import Texttitle from './Texttitle.js';
import Tableofarmor from './Armortable.js';
import 'aos/dist/aos.css'; 
import Aos from 'aos';
import { useEffect } from 'react';


const Armor = () => {

    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );

    return (
        <div>
                        <Header/>
            <Texttitle 
            title='ARMOR STEELS'
            caption = 'Armor steels are favorable for the defense industry by offering better welding properties, repairability, accessibility, and lower cost. There has been a trade-off between better ballistic performance and better formability till we developed our armor steels'
            />
            <Description
                        text= 'Our armor steels offer weight reduction by reducing the necessary thickness for meeting the ballistic shots.' 
                        img= '/assets/defence.jpg'
                        />
            <div data-aos="zoom-in" className="ahss_properties">
                 <Tableofarmor/>
            </div>
            <Footertop link="/contact" arkaplanrengi="#03494e" righttext="Contact us" lefttoptext="Are you interested in our products or services ?"  leftbottomtext=" Feel free to contact us !!"/>
            <Footer/>
        </div>
    )
}

export default Armor

import React from 'react';
import './Footer.css';


function Footer() {
    return (
        <div className="footer_bottom">
                <img src="/assets/logo.png" alt="Girl in a jacket" className="footer_logo"/>
                <h6 className="footer_bottom_text">  Copyright ©2021 all rights reserved</h6>
         </div>
    )
}

export default Footer

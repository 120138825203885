import React, { useEffect } from 'react';
import './Footertop.css'
import 'aos/dist/aos.css'; 
import Aos from 'aos';
import {Link} from "react-router-dom";

const Footertop = (props)  => {
    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );
    return (
        <div data-aos="zoom-in" style={{backgroundColor: props.arkaplanrengi, borderRadius: props.borderradius}} className="footer_top">         
                <div className = " footer_top_left">
                    <h1> {props.lefttoptext}</h1>
                    <h4>{props.leftbottomtext}</h4>
                </div>
                <div className = " footer_top_right">
                    <Link to=  {props.link} style={{textDecoration: 'none', color: 'white'}}> 
                        <h4> {props.righttext} </h4>
                    </Link>
                </div>
            </div>   
    )
}

export default Footertop

import React from 'react';
import './News2.css' ;
import 'aos/dist/aos.css'; 
import Aos from 'aos';
import { useEffect } from 'react';


const News2 = (props) => {

    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );
    return (
        <div className="news2" style={{paddingTop:'1%', paddingBottom:'3%' }}  >
            <div data-aos="zoom-in" className="news2left" style={{backgroundImage: `url(${process.env.PUBLIC_URL + props.img  })`}}  >
                    <div style={{color:'white', backgroundColor: props.yaziarkaplanrengi, width:'70%', marginTop:'20%', marginLeft:'5%'}} >
                        <h2>{props.text1} </h2>
                    </div>
                    <div style={{color:'white', backgroundColor: props.yaziarkaplanrengi, width:'72%', marginTop:'2%', marginLeft:'5%'}}>
                        <h2>{props.text2} </h2>
                    </div>
                    <div style={{color:'white', backgroundColor: props.yaziarkaplanrengi, width:'60%', marginTop:'2%', marginLeft:'5%', marginBottom:'20%'}}>
                        <h4> {props.text3} </h4>
                    </div>
            </div>
            <div data-aos="zoom-in" className="news2right" style={{marginTop:'auto', marginBottom:'auto', justifyContent:'center'}} >
                <div className="news2right_top" style={{backgroundColor: props.sagarkaplanrengi, color:'white', marginRight:'10%', borderRadius:'4%', padding:'3%'}} >
                    <h3> {props.righttexttitle} </h3>
                    <h4>{props.righttext1}  </h4>
                    <h4> {props.righttext2} </h4>
                </div>
            </div>
            
        </div>
    )
}

export default News2

import React, { useEffect } from 'react';
import "./Sectors.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import 'aos/dist/aos.css'; 
import Aos from 'aos';

const useStyles = makeStyles({

    media: {
      height: 280,
    },

    title: {
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    text: {
        fontFamily: 'sans-serif',
    }
  });

const Sectors =() => {
    const classes = useStyles();
    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );

    return (
        <div className="sectors" >
            <h2 data-aos="zoom-in" className="sectors_tag"> SECTORS </h2>
            <div className="sectors_cards">
                <Card data-aos="fade-right" className="card">
                    <CardMedia
                        className={classes.media}
                        image="/assets/biomedical.jpg"
                    />
                    <CardContent fontWeight="fontWeightBold" >
                        <Typography  gutterBottom variant="h5" component="h2" className={classes.title}>
                            BIOMEDICAL
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.text} >
                        Our novel processing method and alloy pave the way for using nickel-free stainless steel in biomedical applications. It is MRI compatible and shows higher strength and ductility behavior compared to both CoCr alloys and 316L grades.
                            
                        </Typography>
                    </CardContent>
                </Card>
                <Card data-aos="zoom-in" className="card">
                    <CardMedia
                        className={classes.media}
                        image="/assets/defence.jpg"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.title} >
                            DEFENCE
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                        Our armor steel grades and processing technologies for armored parts are changing the game in the war zone by enabling lower weights for armored vehicles. Armored vehicles equipped with our solutions have higher maneuverability in the battleground.
                        </Typography>
                    </CardContent>
                </Card>
                <Card data-aos="zoom-in" className="card">
                    <CardMedia
                        className={classes.media}
                        image="/assets/automotive.jpg"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                            AUTOMOTIVE
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.text} >
                        Our 3rd generation advanced high strength steels reduce the weight of vehicles with raising the safety of passengers. Our patented processing technology enables having nano-sized austenite grains in a martensitic matrix and enables higher energy absorption.                        </Typography>
                    </CardContent>
                </Card>
            </div>

 
            
        </div>
    )
}

export default Sectors

import React from 'react';
import "./Header.css";
import {Link} from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

function Header() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <header className="header_main">
            <Link to="/" style={{textDecoration: 'none', paddingLeft: '5%'}}> 
                <div className="header__mainleft"> 
                    <h4 className="header_lef_element" > OBER</h4>        
                    <h4 className="header_lef_element2" >TECHNOLOGIES</h4>
                </div>
            </Link>  
            <div className="header__mainright"> 
                <Link to="/solutions"> <button className="header__button" > SOLUTIONS </button> </Link>
                <Link to="/about"> <button className="header__button" > ABOUT US </button> </Link>
                <Link to="/contact"> <button className="header__button" > CONTACT </button></Link>
            </div>
            <div className="header__mainright_mobile">
                <MenuIcon onClick={handleClick} />
                <div className="header_menu" >
                    <Menu 
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Link to="/solutions" style={{textDecoration:'none'}}> <MenuItem style={{backgroundColor:'#03494e', color: 'white', border: '1px solid white'}} onClick={handleClose}>SOLUTIONS</MenuItem> </Link>
                        <Link to="/about" style={{textDecoration:'none'}}> <MenuItem style={{backgroundColor:'#03494e', color: 'white', border: '1px solid white'}} onClick={handleClose}>ABOUT US</MenuItem> </Link>
                        <Link to="/contact" style={{textDecoration:'none'}}> <MenuItem style={{backgroundColor:'#03494e', color: 'white',border: '1px solid white'}} onClick={handleClose}>CONTACT</MenuItem> </Link>
                    </Menu>
                </div>
            </div>
         
        </header>
    )
}

export default Header;


import React, { useEffect } from 'react';
import Description from './Description';
import Footer from './Footer';
import Footertop from './Footertop';
import Header from './Header';
import EnhancedTable from './Tableofahss';
import Texttitle from './Texttitle.js';
import 'aos/dist/aos.css'; 
import Aos from 'aos';
import Mechanicalsliderahss from './Mechanicalsliderahss';
import { useMediaQuery } from 'react-responsive';

const Ahss = () => {

    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 780px)'
      });

    return (
        <div>
            <Header/>
            <div>
                <Texttitle 
                title='ADVANCED HIGH STRENGTH STEELS'
                caption = 'AHSS grades are mainly used by the automotive industry. They enable car manufacturers to reduce weights without sacrificing safety requirements. Our high strength and high formability grades open new ways for car manufacturers to reduce weight to further levels.'
             
                />
            </div>
            <Description 
            text= '2nd Gen AHSS grades possess high formability properties and suitable for cold forming operations. Cars that use our steels possess better crash performance with reduced weight. Higher weldability features make them special for the automotive industry.' 
            img= '/assets/crashtest.jpg'
            />
             {isDesktopOrLaptop ?
                <div>
                    <div data-aos="zoom-in" className="ahss_properties">
                        <EnhancedTable />
                    </div>
                </div>
            :           
                <div data-aos="zoom-in" className="ahss_properties">
                    <Mechanicalsliderahss
                        img1= '/assets/DP800-Mech.png' 
                        img2= '/assets/DP1000-Mech.png'
                        img3= '/assets/TRIP800-Mech.png'
                        img4= '/assets/CP1200-Mech.png'
                                />   
                </div>
            } 


            <Footertop link="/contact" arkaplanrengi="#03494e" righttext="Contact us" lefttoptext="Are you interested in our products or services ?"  leftbottomtext=" Feel free to contact us !!"  />
            <Footer/>

        </div>
    )
}

export default Ahss

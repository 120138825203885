import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import './Description.css';
import './crashtest.jpg'
import 'aos/dist/aos.css'; 
import Aos from 'aos';





const Description = (props) => {

    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );

    return (
        <div  className="describtion_main">
            <div data-aos="zoom-in" style={{backgroundImage: `url(${process.env.PUBLIC_URL + props.img  })` }} className="describtion_main_image" >
                
            </div>
            <div data-aos="zoom-in" className="describtion_main_text">
                <Typography variant="body2" color="textSecondary" component="p"  > {props.text} </Typography> 
            </div>
            
        </div>
    )
}

export default Description




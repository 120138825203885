import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import './Sliderofproducts.css';
import Navbar from './Navbar.js';
import { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
// import Swiper core and required modules
import SwiperCore, {
    Scrollbar
  } from 'swiper/core';
import 'aos/dist/aos.css'; 
import Aos from 'aos';
import {Link} from "react-router-dom";

SwiperCore.use([Scrollbar]);

const Sliderofproducts = () => { 
   
    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 780px)'
      });
    return (
        <div >

                <Swiper slidesPerView={isDesktopOrLaptop ? '3.5' : '1.5'} spaceBetween={30} scrollbar={{
            "hide": true
            }} className="mySwiper">
            <SwiperSlide>
                <Link to="/products/ahss2gen" style={{textDecoration: 'none', textAlign: 'center'}}> 
                    <div data-aos="fade-right" className="slide " style={{ backgroundImage: 'linear-gradient(135deg, #243949, #517fa4)' }}>
                        <h2 > 2nd Generation Advanced High Strength Steels </h2>
                    </div>
                </Link>  
            </SwiperSlide>
            <SwiperSlide>
                <Link to="/products/ahss3gen" style={{textDecoration: 'none', textAlign: 'center'}}> 
                    <div data-aos="zoom-in" className="slide " style={{ backgroundImage: 'linear-gradient(135deg, #065767  , #076679)' }}>
                        <h2> 3rd Generation Advanced High Strength Steels </h2>
                    </div>
                </Link>  
            </SwiperSlide>
            <SwiperSlide>
                <Link to="/bearing" style={{textDecoration: 'none', textAlign: 'center'}}> 
                    <div data-aos="zoom-in" className="slide " style={{ backgroundImage: 'linear-gradient(135deg,   #0a87a0  , #0b9bb8)' }} >
                            <h2> Bearing Steels for Higher Wear Resistance </h2>
                    </div>
                </Link> 
            </SwiperSlide>
            <SwiperSlide>
                    <Link to="/novelhotstamping" style={{textDecoration: 'none', textAlign: 'center'}}> 
                            <div data-aos="zoom-in" className="slide " style={{ backgroundImage: 'linear-gradient(135deg,  #05777f , #068e98)' }}>
                                    <h2> Novel Hot Stamping Technology </h2>
                            </div>
                    </Link> 
            </SwiperSlide>
            <SwiperSlide>
                    <Link to="/nickelfreestainless" style={{textDecoration: 'none', textAlign: 'center'}}> 
                        <div data-aos="zoom-in" className="slide " style={{ backgroundImage: 'linear-gradient(135deg,  #08768c, #0a859e )' }}>
                                <h2> Nickel Free Stainless Steel for Biomedical</h2>
                        </div>
                    </Link> 
            </SwiperSlide>
            <SwiperSlide>
                <Link to="/armor" style={{textDecoration: 'none', textAlign: 'center'}}> 
                        <div data-aos="zoom-in" className="slide " style={{ backgroundImage: 'linear-gradient(135deg,  #0db4d6 , #0ec4e8 )' }}>
                                <h2> Armor Steels for Ultra Light Weighting </h2>
                        </div> 
                </Link> 
             </SwiperSlide>
            </Swiper>

        </div>
    )
}

export default Sliderofproducts

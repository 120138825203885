import React, { useEffect } from 'react';
import Header from './Header.js';
import Description from './Description';
import Footer from './Footer';
import Footertop from './Footertop';
import Texttitle from './Texttitle.js';


function Hotstamping() {
    return (
        <div>
              <Header/>
            <div>
                <Texttitle 
                title='NOVEL HOT STAMPING TEHCHNOLOGY'
                caption = 'Hot stamping enables manufacturers to produce complex body-in-white parts without having forming difficulties. Martensitic microstructure of the parts after hot stamping gives the highest possible strength. '
             
                />
            </div>
            <Description 
            text= 'The drawback of hot-stamped parts is that they are not good at buckling since they are lack ductility which reduces their energy absorption capacity. Our technology creates a martensitic matrix with nanosized austenite particles and behaves like a nano-composite. Thus, it enables us to use hot stamping technology for chassis parts (which can not be done by conventional hot stamping) along with body-in-white parts. ' 
            img= '/assets/autopart.jpg'
            />
            <Description 
            text= 'Chassis parts produced by our technology offer 5x better fatigue resistance than cold-formed CP800 steel, 10x better fatigue resistance than coated hot stamped parts (boron steels), without even requiring coating. It has lower H embrittlement and decarburization layer and can be used after micron-sized descaling such as sandblasting  ' 
            img= '/assets/suspension.jpg'
            />
                        <Description 
            text= 'Apart from being the first company to use hot stamping technology for suspension parts, we also reduced the duration of hot stamping treatment and enable energy saving. We enable using steel sheets for suspension parts compare to energy&water intensive hot forging method.  ' 
            img= '/assets/energy.jpg'
            />
            <Footertop link="/contact" arkaplanrengi="#03494e" righttext="Contact us" lefttoptext="Are you interested in our products or services ?"  leftbottomtext=" Feel free to contact us !!"  />
            <Footer/>
        </div>
    )
}

export default Hotstamping

import React, { useEffect, useState } from 'react';
import "./Sectionfirst.css";
import TextTransition, { presets } from "react-text-transition";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withTheme } from '@material-ui/styles';

const TEXTS = [
    "a sustainable",
    "a better",
    "a greener",
    "a cleaner",
    "a safer",
    "a smarter",
  ];
  

  
  
  const useStyles = makeStyles( (theme) => ({
  
      goDown: {
          color: '#ff6600',
          width: '100%',
          fontSize: '4rem',
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            color: '#ffffff',
            fontSize: '5rem',
            
          },
        
          
      },

    
    }));
  
function Sectionfirst() {
    const classes = useStyles();
    const [index, setIndex] = useState(0);

    useEffect(() => {
      const intervalId = setInterval(() =>
        setIndex(index => index + 1),
        3000 // every 3 seconds
      );
      return () => clearTimeout(intervalId);
    }, []);

    return (
        <div className="section_first">
            <div className="section_first_textcontainer">
                <h1> Develops for<span class="spanintext">  <TextTransition
        text={ TEXTS[index % TEXTS.length] }
        springConfig={ presets.wobbly } />
             </span> future </h1>
                <button className="section_first_textcontainer_button"> Read More </button>
            </div>
                <ExpandMoreIcon className={classes.goDown} />
            
 
        </div>
    )
}

export default Sectionfirst

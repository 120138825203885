import React from 'react'
import Header from './Header.js'

function Contact() {
    return (
        <div>
            <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/office.jpg'  })`, minHeight: '120vh', width:'100%'}} className="products" >
                <Header/>
                <div  className="products_main">
                    <h1 style={{color: 'white', borderColor: 'white', textAlign:'center'}} className="solutions" > CONTACT <br/> US <br/> </h1>
                    <h2 style={{color: 'white', borderColor: 'white', textAlign:'center'}} className="solutions" > info@oberarge.com </h2>
                </div>
            </div>
        </div>
    )
}

export default Contact

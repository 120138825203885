import React, { useEffect } from 'react';
import './News.css';
import 'aos/dist/aos.css'; 
import Aos from 'aos';

const News = () => {
    useEffect(()=> { 
        Aos.init({duration: 2000});
    }, []
    );
    return (
        <div className="news" style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/main_photo2.jpg"  })`}} >
            <div className="news_texts">
                <div  data-aos="zoom-in" className="news__firsttextfield" >
                    <h2> Do you know that the transportation is responsible for 29% of gas emission ? </h2>   
                </div>
                <div data-aos="flip-left" className="news__secondtextfield">
                    <h4>Weight reduction of vehicles plays a very important role in improving fuel efficiency and lowering emissions. Ober develops cutting-edge technologies for weight reduction with increased safety properties.  </h4>
                </div>
            </div>
       </div>
    )
}

export default News

import React from 'react';
import Description from './Description';
import Footer from './Footer';
import Footertop from './Footertop';
import Header from './Header';
import Texttitle from './Texttitle.js';

function Nickelfreestainless() {
    return (
        <div>
            <Header/>
            <Texttitle 
            title='NICKEL FREE STAINLESS STEEL'
            caption = '316L and Co-Cr alloys are commonly used in biomedical applications due to yielding high strength/ductility, corrosion resistance, and MRI compatibility properties. However, both alloys have a high amount of Ni content and release Ni when it is used inside of the human body. Ni release can cause allergic reactions and more importantly, it is carcinogenic'
            />
            <Description
                        text= 'Our nickel-free and low nickel (<2%) stainless steel grades are breakthroughs in biomedical engineering. Nickel is vital for MRI compatibility of alloys since it makes the matrix para-magnetic FCC crystal structure. We are replacing Ni with body-friendly N alloy with special treatment. Our product has shown better biocompatibility in cytotoxicity tests compared to 316L grades. Moreover, strength is 2x higher than 316L grade and offers using less material for the same load-bearing capacity.  ' 
                        img= '/assets/biomedical.jpg'
                        />
            <Footertop link="/contact" arkaplanrengi="#03494e" righttext="Contact us" lefttoptext="Are you interested in our products or services ?"  leftbottomtext=" Feel free to contact us !!"/>
            <Footer/>
        </div>
    )
}

export default Nickelfreestainless
